// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---atacama-packages-packages-addons-ecommerce-addon-src-pages-agb-js": () => import("./../../../../../../atacama-packages/packages/addons/ecommerce-addon/src/pages/agb.js" /* webpackChunkName: "component---atacama-packages-packages-addons-ecommerce-addon-src-pages-agb-js" */),
  "component---atacama-packages-packages-addons-ecommerce-addon-src-pages-display-js": () => import("./../../../../../../atacama-packages/packages/addons/ecommerce-addon/src/pages/display.js" /* webpackChunkName: "component---atacama-packages-packages-addons-ecommerce-addon-src-pages-display-js" */),
  "component---atacama-packages-packages-addons-ecommerce-addon-src-pages-versand-und-zahlung-js": () => import("./../../../../../../atacama-packages/packages/addons/ecommerce-addon/src/pages/versand-und-zahlung.js" /* webpackChunkName: "component---atacama-packages-packages-addons-ecommerce-addon-src-pages-versand-und-zahlung-js" */),
  "component---atacama-packages-packages-addons-ecommerce-addon-src-pages-widerruf-js": () => import("./../../../../../../atacama-packages/packages/addons/ecommerce-addon/src/pages/widerruf.js" /* webpackChunkName: "component---atacama-packages-packages-addons-ecommerce-addon-src-pages-widerruf-js" */),
  "component---atacama-packages-packages-addons-rolex-addon-src-interness-brands-addon-templates-brand-rolex-js": () => import("./../../../../../../atacama-packages/packages/addons/rolex-addon/src/@interness/brands-addon/templates/brand/rolex.js" /* webpackChunkName: "component---atacama-packages-packages-addons-rolex-addon-src-interness-brands-addon-templates-brand-rolex-js" */),
  "component---atacama-packages-packages-addons-rolex-addon-src-pages-uhren-rolex-geschichte-js": () => import("./../../../../../../atacama-packages/packages/addons/rolex-addon/src/pages/uhren/rolex/geschichte.js" /* webpackChunkName: "component---atacama-packages-packages-addons-rolex-addon-src-pages-uhren-rolex-geschichte-js" */),
  "component---atacama-packages-packages-addons-rolex-addon-src-pages-uhren-rolex-kollektion-js": () => import("./../../../../../../atacama-packages/packages/addons/rolex-addon/src/pages/uhren/rolex/kollektion.js" /* webpackChunkName: "component---atacama-packages-packages-addons-rolex-addon-src-pages-uhren-rolex-kollektion-js" */),
  "component---atacama-packages-packages-addons-rolex-addon-src-pages-uhren-rolex-kontakt-js": () => import("./../../../../../../atacama-packages/packages/addons/rolex-addon/src/pages/uhren/rolex/kontakt.js" /* webpackChunkName: "component---atacama-packages-packages-addons-rolex-addon-src-pages-uhren-rolex-kontakt-js" */),
  "component---atacama-packages-packages-addons-rolex-addon-src-pages-uhren-rolex-neue-modelle-2023-js": () => import("./../../../../../../atacama-packages/packages/addons/rolex-addon/src/pages/uhren/rolex/neue-modelle-2023.js" /* webpackChunkName: "component---atacama-packages-packages-addons-rolex-addon-src-pages-uhren-rolex-neue-modelle-2023-js" */),
  "component---atacama-packages-packages-addons-rolex-addon-src-pages-uhren-rolex-raeumlichkeiten-js": () => import("./../../../../../../atacama-packages/packages/addons/rolex-addon/src/pages/uhren/rolex/raeumlichkeiten.js" /* webpackChunkName: "component---atacama-packages-packages-addons-rolex-addon-src-pages-uhren-rolex-raeumlichkeiten-js" */),
  "component---atacama-packages-packages-addons-rolex-addon-src-pages-uhren-rolex-uhrmacherkunst-js": () => import("./../../../../../../atacama-packages/packages/addons/rolex-addon/src/pages/uhren/rolex/uhrmacherkunst.js" /* webpackChunkName: "component---atacama-packages-packages-addons-rolex-addon-src-pages-uhren-rolex-uhrmacherkunst-js" */),
  "component---atacama-packages-packages-addons-rolex-addon-src-pages-uhren-rolex-wartung-js": () => import("./../../../../../../atacama-packages/packages/addons/rolex-addon/src/pages/uhren/rolex/wartung.js" /* webpackChunkName: "component---atacama-packages-packages-addons-rolex-addon-src-pages-uhren-rolex-wartung-js" */),
  "component---atacama-packages-packages-addons-rolex-addon-src-pages-uhren-rolex-wartungsverfahren-js": () => import("./../../../../../../atacama-packages/packages/addons/rolex-addon/src/pages/uhren/rolex/wartungsverfahren.js" /* webpackChunkName: "component---atacama-packages-packages-addons-rolex-addon-src-pages-uhren-rolex-wartungsverfahren-js" */),
  "component---atacama-packages-packages-themes-theme-sonora-src-interness-brands-addon-templates-brand-default-js": () => import("./../../../../../../atacama-packages/packages/themes/theme-sonora/src/@interness/brands-addon/templates/brand/default.js" /* webpackChunkName: "component---atacama-packages-packages-themes-theme-sonora-src-interness-brands-addon-templates-brand-default-js" */),
  "component---atacama-packages-packages-themes-theme-sonora-src-interness-brands-addon-templates-brand-seiko-js": () => import("./../../../../../../atacama-packages/packages/themes/theme-sonora/src/@interness/brands-addon/templates/brand/seiko.js" /* webpackChunkName: "component---atacama-packages-packages-themes-theme-sonora-src-interness-brands-addon-templates-brand-seiko-js" */),
  "component---atacama-packages-packages-themes-theme-sonora-src-interness-brands-addon-templates-brand-tag-heuer-js": () => import("./../../../../../../atacama-packages/packages/themes/theme-sonora/src/@interness/brands-addon/templates/brand/tag-heuer.js" /* webpackChunkName: "component---atacama-packages-packages-themes-theme-sonora-src-interness-brands-addon-templates-brand-tag-heuer-js" */),
  "component---atacama-packages-packages-themes-theme-sonora-src-interness-brands-addon-templates-brand-tudor-js": () => import("./../../../../../../atacama-packages/packages/themes/theme-sonora/src/@interness/brands-addon/templates/brand/tudor.js" /* webpackChunkName: "component---atacama-packages-packages-themes-theme-sonora-src-interness-brands-addon-templates-brand-tudor-js" */),
  "component---atacama-packages-packages-themes-theme-sonora-src-interness-brands-addon-templates-brands-index-js": () => import("./../../../../../../atacama-packages/packages/themes/theme-sonora/src/@interness/brands-addon/templates/brands/index.js" /* webpackChunkName: "component---atacama-packages-packages-themes-theme-sonora-src-interness-brands-addon-templates-brands-index-js" */),
  "component---atacama-packages-packages-themes-theme-sonora-src-pages-404-js": () => import("./../../../../../../atacama-packages/packages/themes/theme-sonora/src/pages/404.js" /* webpackChunkName: "component---atacama-packages-packages-themes-theme-sonora-src-pages-404-js" */),
  "component---atacama-packages-packages-themes-theme-sonora-src-pages-kontakt-js": () => import("./../../../../../../atacama-packages/packages/themes/theme-sonora/src/pages/kontakt.js" /* webpackChunkName: "component---atacama-packages-packages-themes-theme-sonora-src-pages-kontakt-js" */),
  "component---atacama-packages-packages-themes-theme-sonora-src-pages-thanks-js": () => import("./../../../../../../atacama-packages/packages/themes/theme-sonora/src/pages/thanks.js" /* webpackChunkName: "component---atacama-packages-packages-themes-theme-sonora-src-pages-thanks-js" */),
  "component---atacama-packages-packages-web-core-src-pages-datenschutz-social-mdx": () => import("./../../../../../../atacama-packages/packages/web-core/src/pages/datenschutz-social.mdx" /* webpackChunkName: "component---atacama-packages-packages-web-core-src-pages-datenschutz-social-mdx" */),
  "component---atacama-packages-packages-web-core-src-pages-info-js": () => import("./../../../../../../atacama-packages/packages/web-core/src/pages/---info.js" /* webpackChunkName: "component---atacama-packages-packages-web-core-src-pages-info-js" */),
  "component---atacama-packages-packages-web-core-src-templates-page-legal-js": () => import("./../../../../../../atacama-packages/packages/web-core/src/templates/page/legal.js" /* webpackChunkName: "component---atacama-packages-packages-web-core-src-templates-page-legal-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-newsletter-js": () => import("./../../../src/pages/newsletter.js" /* webpackChunkName: "component---src-pages-newsletter-js" */),
  "component---src-pages-service-mdx": () => import("./../../../src/pages/service.mdx" /* webpackChunkName: "component---src-pages-service-mdx" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */)
}

